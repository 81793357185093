@import '../../../../scss/theme-bootstrap';

.site-header-formatter {
  @include transition(transform 0.25s ease-in-out);
  height: $gnav-mobile-height;
  position: relative;
  @include breakpoint($landscape-up) {
    height: $gnav-height;
  }
  &:hover {
    @include breakpoint($landscape-up) {
      @include transition(background 0.25s ease-in-out);
      background: $color-white;
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      @include breakpoint($landscape-up) {
        background: $color-neutral-black;
      }
    }
    .site-header-formatter__inner-content {
      @include breakpoint($landscape-up) {
        @include transition(background 0.25s ease-in-out);
        background: $color-white;
      }
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        @include breakpoint($landscape-up) {
          background: $color-neutral-black;
        }
      }
    }
  }
  &--sticky,
  &--hidden {
    .site-header-formatter {
      &__inner,
      &__inner-content {
        @include breakpoint($landscape-up) {
          background: $color-white;
        }
      }
    }
    &.product-full--genaissance,
    &.mpp-container--dark-background {
      .site-header-formatter {
        &__inner,
        &__inner-content {
          @include breakpoint($landscape-up) {
            background: $color-neutral-black;
          }
        }
      }
    }
  }
  .gnav-offers-hidden &,
  &.site-header-formatter--sticky {
    height: $navbar-mobile-height;
    @include breakpoint($landscape-up) {
      height: $navbar-height;
      background: $color-white;
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        @include breakpoint($landscape-up) {
          background: $color-neutral-black;
        }
      }
    }
  }
  &__inner {
    @include transition(transform 0.25s ease-in-out);
    position: fixed;
    top: auto;
    width: 100%;
    z-index: $gnav-fixed-z-index;
    .site-header-formatter--sticky & {
      z-index: $gnav-fixed-z-index + 1;
      background: $color-white;
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        background: $color-neutral-black;
      }
    }
    // Hide the sticky Nav when scrolling down.
    .site-header-formatter--hidden.site-header-formatter--sticky & {
      @include transform(translateY(-100%));
    }
    .gnav-util-overlay-active & {
      @include breakpoint($landscape-up) {
        z-index: $gnav-fixed-z-index + 1;
      }
      &.site-header-formatter--sticky {
        @include breakpoint($landscape-up) {
          z-index: $gnav-fixed-z-index + 1;
        }
      }
    }
  }
  &__top {
    @include transition(height 0.25s ease, opacity 0.25s ease);
    background: $color-white;
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      background: $color-neutral-black;
    }
    .header-offers-banner-hidden &,
    .site-header-formatter--sticky & {
      height: 0;
      opacity: 0;
    }
  }
  &__top-container {
    align-items: center;
    border-bottom: 1px solid $color-silver;
    position: relative;
    height: 100%;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    @include breakpoint($landscape-up) {
      max-width: $gnav-max-width;
      margin: 0 auto;
      position: relative;
      z-index: $gnav-fixed-z-index + 1;
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      border-bottom: 1px solid $color-darker-gray;
    }
  }
  &__language-chooser {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      @if $cr22 {
        padding-#{$ldirection}: 26px;
      }
      @else {
        padding-#{$ldirection}: 25px;
      }
    }
  }
  &__top-utility {
    display: none;
    @include breakpoint($landscape-up) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-#{$rdirection}: #{$container-pad-landscape};
    }
    .selectBox-label {
      font-size: 12px;
      font-family: $font--neue-haas-unica-pro;
      color: $color-neutral-gray;
      text-decoration: none;
    }
  }
  &__header {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 100%;
    background: $color-white;
    @include breakpoint($landscape-up) {
      background: transparent;
      padding: 0;
    }
    &.search-is-active,
    .active-gnav & {
      @include breakpoint($landscape-up) {
        background: $color-white;
      }
    }
    .product-full--genaissance & {
      background: $color-neutral-black;
      &:not(.search-is-active) {
        @include breakpoint($landscape-up) {
          background: transparent;
        }
      }
    }
    @if $cr22 {
      .mpp-container--dark-background & {
        background: $color-neutral-black;
      }
    }
  }
  &__offers {
    max-width: 100%;
    overflow: hidden;
    flex: 1;
  }
  &__bar {
    padding: 0 $container-pad-small 0;
    align-items: center;
    display: flex;
    height: $navbar-height;
    position: relative;
    justify-content: space-between;
    @include breakpoint($landscape-up) {
      justify-content: flex-start;
      padding: 0;
      max-width: $gnav-max-width;
      margin: 0 auto;
    }
  }
  &__logo {
    display: block;
    width: 33.33333%;
    @include breakpoint($landscape-up) {
      position: relative;
      margin-#{$rdirection}: 0;
      margin-#{$ldirection}: $container-pad-landscape;
      width: 125px;
    }
    svg {
      margin: 0 auto;
      width: 110px;
      @include breakpoint($landscape-up) {
        width: 125px;
      }
    }
  }
  // Mobile menu trigger (Hamburger).
  &__menu-icon {
    cursor: pointer;
    clear: none;
    margin: auto;
    .active-gnav & {
      display: none;
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
    svg {
      width: 16px;
      height: 12px;
    }
    label {
      display: block;
    }
  }
  // Only displays for mobile, takes user back to main sections list.
  &__mobile-menu-header {
    cursor: pointer;
    display: none;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    span {
      display: inline-block;
      font-size: 12px;
    }
    &-label {
      display: block;
      padding-#{$ldirection}: $gnav-mobile-hpad--outer;
    }
    .header-nav-section__trigger:checked ~ & {
      display: block;
      font-weight: 700;
      height: $gnav-mobile-link-height;
      line-height: $gnav-mobile-link-height;
      margin: 0;
      position: fixed;
      top: 0;
      width: 100%;
      background-color: $color-white;
      z-index: 1;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__links {
    @include breakpoint($landscape-up) {
      display: none;
    }
    &-link {
      font-family: $font--text;
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 17px;
      color: $color-neutral-gray;
      padding: 0 $gnav-mobile-hpad--outer;
      a {
        text-decoration: none;
        color: $color-neutral-dark-gray;
      }
    }
  }
  &__links-container {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__background {
    @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
    background-color: $color-white;
    height: 0;
    #{$ldirection}: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: $gnav-fixed-z-index - 1;
    @include breakpoint($landscape-up) {
      top: $gnav-height;
    }
    .site-header-formatter__trigger-reset:checked ~ .site-header-formatter__sections & {
      @include breakpoint($landscape-up) {
        opacity: 0;
        visibility: hidden;
      }
    }
    .header-nav-section__trigger:checked ~ & {
      @include breakpoint($landscape-up) {
        height: 0;
        opacity: 1;
        visibility: visible;
      }
    }
    .site-header-formatter--sticky &,
    .gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $navbar-height;
      }
    }
  }
  &__sections {
    display: none;
    @include breakpoint($landscape-up) {
      padding: 0;
      #{$ldirection}: 0;
      margin-top: 0;
      display: block;
      position: relative;
      height: 100%;
      flex: 1;
    }
    .site-header-formatter__mobile-trigger:not(:checked) ~ & {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
    .site-header-formatter__mobile-trigger:checked ~ & {
      background-color: $color-white;
      height: calc(100% - #{$offerbar-mobile-height} - #{$navbar-mobile-height} + 1px);
      position: fixed;
      top: $navbar-mobile-height + $offerbar-mobile-height;
      #{$ldirection}: 0;
      width: 100%;
      display: block;
      overflow-y: auto;
      z-index: $gnav-fixed-z-index;
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        background: $color-neutral-black;
      }
      @if $cr22 {
        padding-top: 20px;
      }
      @include breakpoint($landscape-up) {
        background-color: unset;
        height: 100%;
        padding: 0;
        position: relative;
        width: inherit;
        @if $cr22 {
          padding-top: 0;
        }
      }
    }
    .site-header-formatter--sticky .site-header-formatter__mobile-trigger:checked ~ & {
      top: $navbar-mobile-height;
      height: 100vh;
      @if $cr22 {
        padding-top: 20px;
      }
      @include breakpoint($landscape-up) {
        top: auto;
        height: 100%;
        @if $cr22 {
          padding-top: 0;
        }
      }
    }
    .site-header-formatter__trigger-reset:not(:checked) ~ & {
      @include breakpoint($landscape-up) {
        z-index: unset;
        width: 80%;
        display: block;
        overflow-y: inherit;
        position: relative;
        top: auto;
      }
    }
  }
  &__mobile-menu-icon {
    display: inline-block;
    fill: $color-black;
    opacity: 1;
    position: relative;
    width: $gnav-icon--inline;
    @include breakpoint($landscape-up) {
      background: none;
      display: none;
    }
    &:hover,
    &:active {
      fill: $color-black;
    }
  }
  &--close-icon {
    &,
    input[type='radio'] ~ & {
      cursor: pointer;
      clear: none;
      display: none;
      .active-gnav & {
        display: block;
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      @include breakpoint($landscape-up) {
        display: none;
      }
      &::before {
        display: none;
      }
      .icon {
        width: 16px;
        height: 16px;
      }
    }
    input[type='radio']:checked ~ & {
      &::after {
        display: none;
      }
    }
  }
  // Utility styles
  &__utilities {
    display: flex;
    align-items: center;
    @include breakpoint($landscape-up) {
      width: auto;
      height: 100%;
      gap: 30px;
      margin-#{$ldirection}: 0;
      margin-#{$rdirection}: #{$container-pad-landscape};
    }
    &-item {
      display: inline-block;
      padding-#{$rdirection}: 20px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
    .gnav-util {
      &__content {
        display: none;
        position: fixed;
        top: $gnav-mobile-height;
        overflow-x: hidden;
        #{$rdirection}: 0;
        background: $white;
        border: 1px solid $color-light-gray;
        padding: 0 30px;
        width: 100%;
        z-index: 999;
        @include breakpoint($landscape-up) {
          #{$rdirection}: -#{$container-pad-landscape};
          position: absolute;
          width: 450px;
          top: $navbar-height - 26px;
        }
        .site-header-formatter--sticky & {
          top: $navbar-mobile-height;
          @include breakpoint($landscape-up) {
            top: $navbar-height - 26px;
          }
        }
        .gnav-offers-hidden & {
          top: $navbar-height;
          @include breakpoint($landscape-up) {
            top: $navbar-height - 26px;
          }
        }
        &-header-title {
          font-size: 24px;
          letter-spacing: 0.21px;
          text-align: center;
          padding: 0 0 20px;
        }
        &-header {
          padding: 10px 0 0;
          &-close {
            display: inline-block;
            text-align: $rdirection;
            width: 100%;
            .icon--close {
              width: 20px;
              height: 20px;
              margin: 5px;
              display: inline-block;
            }
          }
          .cart-loading & {
            padding: 10px 0 15px;
          }
        }
      }
      &-nav-section-toggle {
        display: inline-block;
      }
      &__cart {
        line-height: normal;
        font-family: $font--text;
        font-size: 14px;
        font-weight: 400;
        color: $color-darker-gray;
        text-decoration: none;
      }
      &__bag-icon {
        display: inline-block;
        width: 13px;
        height: 17.5px;
        margin-bottom: 4px;
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      &__bag-label {
        display: none;
        letter-spacing: 0.05em;
        @include breakpoint($landscape-up) {
          display: inline-block;
        }
      }
      &__icon {
        color: $black;
        line-height: $header-height;
        position: initial;
        text-decoration: none;
        display: block;
        margin: auto 30px;
        @include breakpoint($landscape-up) {
          position: relative;
        }
        .icon {
          width: 25px;
          height: 25px;
          &::before {
            font-size: 30px;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }
      &-trigger:checked ~ .gnav-util__content {
        display: block;
      }
      &-trigger-label {
        padding: 0;
        &::before,
        &::after {
          display: none;
          visibility: hidden;
          opacity: 0;
        }
      }
    }
    .contextual-links-region {
      position: initial;
    }
    // @todo Add styles for email sign up and search in this section
  }
  &__email-signup {
    display: none;
    @include breakpoint($landscape-up) {
      display: inline-block;
    }
  }
  // Shared overlay for the gnav and utility
  &-overlay {
    &,
    input[type='radio'] ~ & {
      background-color: $color-gray;
      cursor: pointer;
      display: none;
      height: 0;
      #{$ldirection}: 0;
      opacity: 0;
      position: fixed;
      top: 0;
      transition: visibility 0.3s, opacity 0.3s;
      visibility: hidden;
      width: 100%;
      z-index: $gnav-fixed-z-index - 2;
      &::after,
      &::before {
        display: none;
        visibility: hidden;
      }
    }
    .header-nav-section__trigger:checked + .header-nav-section__section + &,
    .gnav-util-trigger:checked + & {
      height: 100%;
      opacity: 0.75;
      visibility: visible;
      @include breakpoint($landscape-up) {
        display: block;
        top: $gnav-height;
      }
    }
    // Open dark overlay for mobile. The previous section works only for PC
    .gnav-util-trigger:checked + & {
      display: block;
      top: $gnav-mobile-height;
      padding: 0;
      @include breakpoint($landscape-up) {
        top: $gnav-height;
      }
      .site-header-formatter--sticky &,
      .gnav-offers-hidden & {
        @include breakpoint($landscape-up) {
          top: $navbar-height;
        }
      }
    }
  }
  &__custom-link {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
    a {
      @include breakpoint($landscape-up) {
        line-height: normal;
        font-family: $font--text;
        font-size: 14px;
        letter-spacing: 0.05em;
        font-weight: 400;
        color: $color-darker-gray;
        text-decoration: none;
        &:hover {
          color: $color-darker-gray;
          text-decoration: none;
          border-bottom: none;
        }
      }
    }
  }
  .icon--logo {
    top: 0;
    @include breakpoint($landscape-up) {
      width: 105px;
      height: 20px;
    }
  }
  .site-header__utility-item__link,
  .site-header-formatter__top-utility-item,
  .site-header-formatter__greetings-text {
    font-size: 14px;
    font-family: $font--neue-haas-unica-pro;
    color: $color-neutral-dark-gray;
    text-decoration: none;
    &:hover {
      color: $color-neutral-black;
    }
    @include breakpoint($landscape-up) {
      font-size: 12px;
    }
  }
  .site-header-formatter__top-utility-item {
    a {
      text-decoration: none;
      color: $color-neutral-dark-gray;
      &:hover {
        color: $color-neutral-black;
      }
    }
  }
  &__greetings-text {
    cursor: pointer;
  }
  // Account Sign Overlay
  .gnav-util__content {
    position: fixed;
    top: $header-height + 1px;
    #{$rdirection}: 0;
    background: $white;
    border: 1px solid $color-black;
    width: 100%;
    z-index: 999;
    @include breakpoint($extra-extra-large-up) {
      #{$rdirection}: calc((100vw - #{$container-max-width}) / 2);
    }
    &-close {
      background: transparent;
      position: absolute;
      z-index: 2;
      #{$rdirection}: 1em;
      top: 1em;
      text-decoration: none;
      width: 22px;
      height: 22px;
      .icon {
        height: 16px;
        width: 17px;
        @include breakpoint($medium-up) {
          height: 20px;
          margin: 0;
          padding: 0;
          width: 22px;
        }
      }
    }
    &-inner {
      ul.error-messages {
        color: $color-error;
        padding: 3px;
      }
    }
    // Email Signup Overlay
    &--signup {
      position: fixed;
      min-height: 250px;
      background-color: $color-white;
      border: solid 1px $color-light-gray;
      #{$ldirection}: 125px;
      #{$rdirection}: auto;
      text-align: center;
      top: 37px;
      text-transform: uppercase;
      width: 382px;
      .gnav-util__content-close {
        border: 0;
        top: 2em;
      }
      .site-email-signup {
        margin: auto;
        padding: 20px;
        &__title {
          display: block;
          margin-bottom: 10px;
          font-size: 15px;
        }
        &__subtitle {
          font-size: 13px;
          font-style: normal;
          text-transform: none;
          width: 90%;
          margin: auto;
          margin-bottom: 20px;
        }
        &__submit {
          display: block;
          text-align: center;
          margin: 0 auto;
        }
        &__fields {
          margin-bottom: 10px;
          text-transform: none;
          &--email,
          &--sms {
            width: 80%;
            border: solid 1px $color-light-gray;
            margin: 13px auto;
          }
        }
        &__messages {
          text-align: center;
          text-transform: none;
        }
        &__success {
          margin-top: 30%;
        }
      }
      input[type='text'].site-email-signup__field {
        width: 100%;
        text-align: center;
        height: 22px;
        line-height: 22px;
        &::placeholder {
          font-size: 10px;
        }
      }
    }
  }
  .gnav-util__content--account {
    display: none;
    background-color: $color-white;
    border: solid 1px $color-light-gray;
    #{$ldirection}: auto;
    #{$rdirection}: 74px;
    max-width: 400px;
    top: 37px;
    -webkit-overflow-scrolling: touch;
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      width: 350px;
      display: block;
      height: auto;
      overflow-y: hidden;
      top: 40px;
    }
    @include breakpoint($extra-extra-large-up) {
      #{$rdirection}: calc((100vw - #{$container-max-width}) / 2);
    }
    @include breakpoint($medium-only) {
      width: 350px;
      display: block;
    }
    .gnav-util__content-close {
      border: 0;
      top: 2em;
    }
  }
  .site-header-formatter__account {
    @include breakpoint($large-up) {
      text-align: center;
      padding: 0;
      cursor: pointer;
    }
  }
  .gnav-util__content--cart {
    width: 350px;
    padding: 0;
    top: 100px;
    .cart-block {
      width: 100%;
      padding: 0;
      &__footer {
        font-family: $font--neue-haas-unica-pro;
        &__subtotal {
          font-size: 11px;
          text-transform: uppercase;
        }
      }
      &__title {
        text-transform: uppercase;
      }
      &__items {
        overflow-y: auto;
        &__item {
          border-bottom: solid 1px $color-light-gray;
          &:first-child {
            border-top: 0;
          }
        }
        .cart-item {
          padding-top: 10px;
          padding-bottom: 10px;
          width: 100%;
          &__size {
            display: none;
          }
          &__info {
            font-size: 12px;
          }
          &__sku-label,
          &__qty {
            font-family: $font--neue-haas-unica-pro;
          }
        }
      }
    }
  }
  .gnav-util--account {
    .site-header__utility-item__link {
      padding-bottom: 3px;
    }
    .gnav-util__content-inner {
      padding: 2em;
    }
    .social-login__divider {
      margin: 20px 0;
    }
    .sign-in {
      text-transform: none;
    }
    input,
    .button {
      margin-top: 10px;
      width: 100%;
    }
    .button {
      color: $color-white;
      &:hover {
        color: $color-white;
      }
    }
    .login {
      &__forgot-password {
        text-transform: none;
        text-decoration: underline;
        margin-top: 8px;
      }
      &__header,
      &__no-account {
        text-transform: none;
      }
      &__registration-alt {
        margin-top: 20px;
      }
    }
    .registration {
      &__show-pass-text,
      &__terms-text,
      &__email-list-text {
        margin-top: 10px;
        display: inline-block;
      }
      &__signin-alt {
        margin-top: 20px;
      }
    }
  }
  &__account-selector {
    height: auto;
    position: relative;
    .menu__link {
      font-size: 12px;
      font-family: $font--neue-haas-unica-pro;
      color: $color-neutral-gray;
      text-decoration: none;
      line-height: 2.5;
      &:hover {
        color: $color-neutral-black;
      }
    }
  }
  &__loyalty-block {
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 15px;
    }
  }
  &__account-menu-list {
    display: none;
    position: relative;
    width: auto;
    box-shadow: none;
    padding: 0;
    top: -15px;
    background: $color-white;
    line-height: 0;
    @include breakpoint($landscape-up) {
      position: absolute;
      width: max-content;
      top: 26px;
      #{$rdirection}: 0;
      padding: 20px;
      z-index: $gnav-fixed-z-index;
      box-shadow: 0 0 4px $color-silver-10;
    }
  }
  &__account-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__mobile-content-toggle {
    height: 16px;
    width: 16px;
    transition: backgroound 0.3s ease-in;
    fill: $color-neutral-dark-gray;
    @include breakpoint($landscape-up) {
      display: none;
    }
    &.icon--minus {
      display: none;
    }
  }
  &__account-selector.expanded {
    .icon--plus {
      display: none;
    }
    .account-menu-list,
    .icon--minus {
      display: block;
    }
  }
  &__search {
    padding: 0;
  }
  .esearch-nav {
    display: none;
  }
  .esearch-nav.active {
    position: absolute;
    width: 100%;
    display: block;
    top: $navbar-mobile-height;
    #{$ldirection}: 0;
    @include breakpoint($landscape-up) {
      top: $navbar-height;
    }
  }
  .menu__item--search {
    position: relative;
    width: 100%;
    cursor: pointer;
    display: block;
    @include breakpoint($landscape-up) {
      @if $cr22 {
        padding: 0 15px;
      } @else {
        @include swap_direction(padding, 0 15px 0 0);
      }
      height: 100%;
      width: auto;
      display: flex;
      align-items: center;
    }
  }
  .menu__item-search-text {
    // overriding inline styles for this div in mobile view
    display: none !important;
    @include breakpoint($landscape-up) {
      display: block !important;
      line-height: normal;
      font-family: $font--text;
      font-size: 14px;
      font-weight: 400;
      color: $color-darker-gray;
      @if $cr22 {
        margin-#{$rdirection}: 10px;
      } @else {
        margin-#{$ldirection}: 5px;
      }
      &:hover {
        color: $color-darker-gray;
        text-decoration: none;
        border-bottom: none;
      }
    }
  }
  &__search-label,
  .icon--search-large {
    display: block;
    &.icon.mvt_search_icon{
      // overriding inline important declaration on the search icon
      display: none !important;
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__search-label {
    display: flex;
    align-items: center;
    width: auto;
    height: 100%;
    padding: 0 15px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .icon--search-large {
    width: 16.1px;
    height: 16.1px;
    @if $cr22 {
      @include breakpoint($landscape-up) {
        display: block;
        fill: $color-darker-gray;
        height: 10px;
        width: 10px;
      }
    }
  }
  .menu__item-search-label {
    @if $cr22 {
      @include breakpoint($landscape-up) {
        align-items: baseline;
        display: flex;
      }
    }
  }
}

// Overrides for specific sections and states applied to html and body.
html.active-gnav {
  overflow: hidden; // No scrolling the body when fixed nav sections are active.
  overflow-y: hidden; // ios safari.
  .site-header-formatter--hidden.site-header-formatter--sticky {
    .site-header-formatter__inner {
      @include transform(unset);
    }
  }
}

body {
  .active-gnav & {
    max-width: 100%;
    overflow: hidden; // No scrolling the body when fixed nav sections are active.
    overflow-y: hidden; // ios safari.
    width: 100%;
    position: relative;
  }
}

// Minimal nav class applied via elc_gnav_minimal_v1 for drupal.
// Class is on body for drupal, may be on html for pg pages.
.elc-gnav-minimal {
  .site-header-formatter__top,
  .site-header-formatter__menu-icon,
  .site-header-formatter__utilities,
  .site-header-formatter__header .site-header-formatter__sections {
    display: none;
  }
  .site-header-formatter__sections {
    .site-header-formatter__mobile-trigger:not(:checked) ~ & {
      @include breakpoint($landscape-up) {
        .elc-gnav-minimal & {
          display: none;
        }
      }
    }
  }
}
