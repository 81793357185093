///
/// @file base/_typography.scss
///
/// \brief Reusable, generic typographic styles used throughout the site.
///
/// Guidelines:
///
/// No layout-related properties (float, margin, padding, position, display,
/// etc.), no template-specific stuff, try to follow the existing naming
/// conventions and method of creating both mixins and extendable placeholders.

// Base styles: each typeface has optimal type treatment that remains consitent
// across the site.
// @setup new brand - examples of how to change to values using web fonts
// $font--heading: "Copperplate Gothic Std 32 AB", sans-serif;
// $font--subheading: "ITC Franklin Gothic", sans-serif;
// $font--text: "Adobe Caslon Pro", serif;
// $font--cta: "Typewriter Elite MT Std", serif;
// $font--typewriter: "Typewriter Elite MT Std", serif;

$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;
$font--arial: Arial, Helvetica, Verdana, sans-serif !default;
$font--arial-times: Times-Roman, 'Times New Roman', Arial, Helvetica, Verdana, sans-serif !default;
$font--arial-times-lino: 'LinotypeDidotRoman', Times-Roman, 'Times New Roman', Arial, Helvetica, Verdana, sans-serif !default;
$font--neue-haas-unica-pro: 'Neue Haas Unica Pro' !default;
$font--neue-haas-unica-pro-medium: 'Neue Haas Unica Pro Medium' !default;
$font--lamer-headline: 'La Mer Headline' !default;
$font--lamer-text: 'La Mer Text' !default;
$font--malone: 'Malone Serif', Times, 'Times New Roman', serif !default;
$font--lars: 'Lars Malone', Helvetica, Arial, sans-serif !default;
$font--text: $font--neue-haas-unica-pro, sans-serif !default;
$font--heading: $font--lamer-headline, serif !default;
$font--subheading: $font--lamer-text, serif !default;
$font--gravograph: 'Gravograph' !default;
$font--engrave: $font--gravograph, serif !default;

///
/// Mixins & Extendables
///
/// These are the generic typography styles across the site.
/// Each style is both a mixin, a silent class and a class.
/// Apply the class directly to the template mark up or use @extend
/// whenever possible, use the mixin version when working inside a
/// media query.
///
///  WARNING only extend silent classes, do NOT @extend regular classes
///
/// Example NO:
/// .store-locator__header {
///   @extend .headline--secondary;
/// }
///
/// Example YES:
/// .store-locator__header {
///   @extend %headline--secondary;
/// }
///
/// Example with Media Query:
/// .store-locator__header {
///   @extend %headline--secondary;
///   @media #{$medium-up} {
///     @include headline--primary;
///   }
/// }
///

// Note: We're only using a mixin here since @extend does not make sense here.
// Using @extend will output more compiled code given the single declaration.
@mixin font--text {
  font-family: $font--text;
}

@mixin font--heading {
  font-family: $font--heading;
}

@mixin font--subheading {
  font-family: $font--subheading;
}

///
/// Heading Styles
/// classes are inside _typography--helpers.scss and loaded last so they have more specificity.
///

/// font styles only

@mixin heading--1 {
  @include font--heading;
  font-size: 25px;
  line-height: 1;
  @include breakpoint($medium-up) {
    font-size: 50px;
  }
}

@mixin heading--2 {
  @include font--heading;
  font-size: 25px;
  line-height: 1;
  @include breakpoint($medium-up) {
    font-size: 36px;
  }
}

@mixin heading--3 {
  @include font--subheading;
  font-size: 18px;
  line-height: 1.5;
  @include breakpoint($medium-up) {
    font-size: 24px;
  }
}

@mixin heading--4 {
  @include font--heading;
  font-size: 14px;
  line-height: 1.2;
  @include breakpoint($medium-up) {
    font-size: 20px;
  }
}

@mixin heading--5 {
  @include font--heading;
  font-size: 12px;
  line-height: 1;
  @include breakpoint($medium-up) {
    font-size: 16px;
  }
}

/// Text Styles

@mixin text--1 {
  @include font--text;
  font-size: $base-mobile-font-size;
  line-height: $base-line-height;
  @include breakpoint($medium-up) {
    font-size: $base-font-size;
  }
}

@mixin text--2 {
  @include font--text;
  font-size: 11px;
  line-height: 1;
  @include breakpoint($medium-up) {
    font-size: 13px;
  }
}

// Styleguide Styles
@mixin style--review {
  @include font--subheading;
  font-size: 24px;
  line-height: 1.5;
}

@mixin style--name {
  @include font--text;
  line-height: 1.2;
  font-size: 13px;
  letter-spacing: 0.1em;
}
